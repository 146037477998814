<template>
  <div class="game-picker-panel">
    <div class="header d-flex justify-content-between align-items-center">
      <h3 class="title">{{$t("Select a game")}}</h3>
      <div class="search-form">
        <div class="icon">
          <b-icon icon="search"/>
        </div>
        <input type="search" v-model="keyword" :placeholder="$t('Search games')">
      </div>
    </div>
    <div class="content">
      <LoadingPanel v-if="loading"/>
      <NotFoundPanel v-else-if="items.length === 0" :message="$t('Cannot find games')" />
      <div class="games" v-else>
        <b-row>
          <b-col md="6" v-for="(game, index) in items" :key="index" class="item">
            <a href="#" @click.prevent="$emit('click', game)" class="d-flex align-items-center">
              <img :src="getWebFileUrl(game.iconUrl)" :title="game.name" />
              <label class="w-100">{{game.name}}</label>
              <i class="fa-solid fa-chevron-right"></i>
            </a>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import gameService from "@/services/gameService";

export default {
  name: "GamePickerPanel",
  props: ["idFeature"],
  data() {
    return {
      loading: true,
      items: [],
      games: [],
      keyword: ""
    }
  },
  async created() {
    let games = this.$store.getters.games;
    if (games) {
      this.loading = false;
    }
    else {
      const response = await gameService.getAll();
      this.loading = false;
      if (!response || response.error)
        return;

      games = response.data;
      await this.$store.dispatch("setGames", games);
    }

    if (this.idFeature && this.idFeature > 0)  {
      const idFeature = parseInt(this.idFeature);
      games = games.filter(x => x.idFeatures.includes(idFeature));
    }
    
    this.games = games;
    this.items = games;
  },
  watch: {
    keyword(newValue) {
      let dataSource = this.games || [];
      if (newValue && newValue.length > 0) {
        const keyword = newValue.toLowerCase();
        dataSource = dataSource.filter(x => x.name.toLowerCase().indexOf(keyword) > -1 || x.uName.toLowerCase().indexOf(keyword) > -1);
      }

      this.items = dataSource;
    }
  }
}
</script>

<style scoped lang="scss">
.game-picker-panel {
  .header {
    padding: 15px 0;
    .title {
      font-weight: 600;
      font-size: 20px;
      margin: 0;
    }

    .search-form {
      position: relative;

      .icon {
        position: absolute;
        color: gray;
        top: 11px;
        left: 12px;
      }

      input {
        height: 100%;
        width: 100%;
        outline: none;
        border: none;
        padding: 10px 0 10px 36px;
        background: #f4f4f4;
        border-radius: 100px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        font-size: 13px;
      }
    }
  }

  .content {
    padding-top: 15px;

    .games {
      .item {
        a {
          padding: 15px 0;
          border-bottom: 1px solid #f2f2f2;
          
          img {
            width: 52px;
            height: 52px;
            object-fit: contain;
            margin-right: 10px;
          }

          label {
            font-size: 16px;
            display: -webkit-box;
            width: 100%;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            max-height: 46px;
            -webkit-line-clamp: 2;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>