<template>
  <div class="page page-game-picker">
    <b-col class="py-3">
      <Breadcrumb :items="[{label: $t('Select a game')}]"/>
      <GamePickerPanel :id-feature="idFeature" @click="onGameClicked"/>
    </b-col>
  </div>
</template>

<script>
import GamePickerPanel from "@/components/panels/game/GamePickerPanel";

export default {
  name: "Picker",
  components: {GamePickerPanel},
  data() {
    return {
      idFeature: this.$route.params.idFeature
    }
  },
  methods: {
    async onGameClicked(game) {
      await this.$router.push({
        name: this.$route.params.callbackRoute,
        params: {
          aliasUrl: game.aliasUrl
        }
      })
    }
  }
}
</script>

<style scoped>

</style>